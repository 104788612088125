.nk-toast {
  top: 100px;
  right: 0px;
  gap: 12px;
  z-index: 9999;
  width: 420px;
  display: flex;
  overflow: hidden;
  align-items: center;
  background-color: #fff;
  padding: 12px 18px 16px 20px;

  &.nk-toast-success,
  &.nk-toast-warning,
  &.nk-toast-error {
    &::after {
      content: "";
      left: 0;
      width: 100%;
      height: 4px;
      position: absolute;
      top: calc(100% - 4px);
      background-color: #ddd;
    }
  }

  &.nk-toast-success {
    &::after {
      background-color: $green;
    }
    .nk-toast {
      &-icon {
        background-color: $green;
      }
    }
  }

  &.nk-toast-error {
    &::after {
      background-color: $red;
    }
    .nk-toast {
      &-icon {
        background-color: $red;
      }
    }
  }

  &.nk-toast-warning {
    &::after {
      background-color: $yellow;
    }
    .nk-toast {
      &-icon {
        background-color: $yellow;
      }
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    color: $white;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background-color: #ddd;
  }

  &-info {
    p {
      color: $darker;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &-btn {
    top: 12px;
    border: 0;
    padding: 0;
    outline: 0;
    right: 12px;
    font-size: 18px;
    position: absolute;
    display: inline-block;
    background: transparent;
    box-shadow: none !important;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .nk-toast {
    width: 90%;
  }
}
