$frame-class: "nk-frame";

.#{$frame-class} {
  position: relative;

  &-children {
    position: absolute;
    transition: all 0.3s linear;
  }

  // Variants
  &-one {
    .#{$frame-class} {
      &-children {
        &-one {
          top: 0;
          left: 0;
          width: 100%;

          @include media-breakpoint-up(sm) {
            width: auto;
            top: 145px;
            left: -15px;
          }

          @include media-breakpoint-up(lg) {
            left: -100px;
          }
        }
        &-two {
          right: 0;
          width: 100%;
          bottom: 0px;
          @include media-breakpoint-up(sm) {
            width: auto;
            right: 20px;
            bottom: 185px;
          }

          @include media-breakpoint-up(lg) {
            right: -20px;
          }
        }
      }
    }
  }

  &-three {
    .#{$frame-class} {
      &-children {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        &-one {
          top: -30px;
          z-index: -1;

          @include media-breakpoint-up(xl) {
            z-index: 1;
            top: -70px;
          }

          @include media-breakpoint-up(lg) {
            top: -60px;
          }

          @include media-breakpoint-up(md) {
            left: -60px;
          }
          @include media-breakpoint-up(sm) {
            left: -30px;
          }
        }
        &-two {
          @include media-breakpoint-up(sm) {
            right: 0px;
            bottom: 24px;
          }

          @include media-breakpoint-up(md) {
            right: -40px;
          }
          @include media-breakpoint-up(lg) {
            bottom: -35px;
          }
        }
      }
    }
  }

  &-four {
    .#{$frame-class} {
      &-children {
        display: none;
        transition: all 0.3s linear;
        @include media-breakpoint-up(lg) {
          display: block;
        }
        &-one {
          top: 140px;
          left: 60px;
          @media (min-width: 1680px) {
            top: 218px;
            left: 455px;
          }
        }
        &-two {
          top: 160px;
          right: 60px;
          @media (min-width: 1680px) {
            top: 207px;
            right: 446px;
          }
        }

        &-three {
          top: 600px;
          left: 185px;
          @include media-breakpoint-up(xl) {
            top: 485px;
            left: 275px;
          }
          @media (min-width: 1620px) {
            top: 565px;
            left: 438px;
          }
        }

        &-four {
          right: 140px;
          top: 620px;
          @include media-breakpoint-up(xl) {
            right: 320px;
          }

          @media (min-width: 1620px) {
            right: 415px;
            top: 595px;
          }
        }
      }
    }
  }

  &-five {
    .#{$frame-class} {
      &-children {
        &-one {
          top: -32px;
          left: -32px;
          z-index: -1;
        }
        &-two {
          top: -24px;
          right: 0;
          @include media-breakpoint-up(md) {
            right: -24px;
          }
        }
        &-three {
          right: 0;
          bottom: -30px;
          z-index: -1;

          @include media-breakpoint-up(md) {
            right: -18px;
          }
        }
      }
    }
  }
}
