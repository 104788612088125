.container {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid gray; */
  padding: 0 1dvw;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 6px 6px rgb(0 0 0 / 0.1);
  scale: 0.85;
}

.imgContainer {
  width: 16px;
  height: 16px;
}

.container > img {
  width: 18px;
  height: 18px;
  background-size: cover;
  margin-bottom: 2px;
  border-radius: 50%;
}

.container > div {
  margin-top: 5px;
}
